<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="类型">
						<span class="xx">*</span>
						<el-select v-model="ruleForm.type" placeholder="请选择类型">
							<el-option label="党课视频" :value="0"></el-option>
							<el-option label="党课资讯" :value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="封面图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.thumb" class="dialog-img" :src="ruleForm.thumb" />
							<p v-if="ruleForm.thumb" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="视频" v-if="ruleForm.type==0">
						<span class="xx">*</span>
						<div class="dialog-up" element-loading-text="视频上传中,请稍等..." v-loading="loading">
							<!-- 预览图 -->
							<video v-if="ruleForm.file" class="dialog-img" controls>
								<source :src="ruleForm.file" type="video/mp4">
							</video>
							<p v-if="ruleForm.file" class="dele" @click="deleVideo">删除</p>
							<el-upload accept=".mp4" :headers="Headers" :before-upload="changeVideo" :action="ActionUrl"
								:on-success="VideoSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="分类">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.class_study_cate_id" placeholder="请选择分类"
							@change="change">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="学习人数">
						<span class="xx"></span>
						<el-input clearable type="number" v-model="ruleForm.hits" placeholder="请输入学习人数"></el-input>
					</el-form-item>
					<el-form-item label="获得积分" v-if="ruleForm.type==1">
						<span class="xx">*</span>
						<el-input clearable type="number" v-model="ruleForm.integral" placeholder="请输入获得积分"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 图文详情 -->
			<content ref="content" @Submitinput="Submitinput">
			</content>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/classroom";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				Headers: {
					token: ''
				},
				loading: false,
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					name: '',
					orderby: 0,
					thumb: '',
					content: '',
					file: '',
					class_study_cate_id: '',
					hits: 0,
					type: 0,
					integral:''
				}
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 视频
			changeVideo() {
				this.loading = true
			},
			// 上传视频
			VideoSuccess(res, file) {
				this.ruleForm.file = res.data.url
				this.loading = false
			},
			// 删除视频
			deleVideo() {
				this.ruleForm.file = ''
			},
			// 获取分类
			getList() {
				this.$http.post(api.Typelist, {
						page: 1,
						limit: 9999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data
						}
					})
			},
			// 删除图片
			dele() {
				this.ruleForm.thumb = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.thumb = res.data.url
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							delete this.ruleForm.create_time
							// 赋值
							this.$refs.content.setContent(res.data.content)
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.getList()
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.ruleForm.hits = 0
				this.$refs.content.emptyContent()
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				console.log(this.ruleForm.type)
				if (this.ruleForm.thumb === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				} else if (!this.ruleForm.file && this.ruleForm.type === 0) {
					ElMessage({
						message: '请上传视频',
						type: 'warning',
					})
				} else if (this.ruleForm.class_study_cate_id === '') {
					ElMessage({
						message: '请选择分类',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.integral === '' && this.ruleForm.type === 1) {
					ElMessage({
						message: '请输入获得积分',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>