import request from './request'

const api = {
	// 获取列表
	lists: '/classstudy/lists',
	// 添加
	add: '/classstudy/add',
	// 删除
	dele: '/classstudy/del',
	// 编辑
	edit: '/classstudy/update',
	// 详情
	info: '/classstudy/read',
	// 分类列表
	Typelist: '/classstudycate/lists',
	// 获取党课堂学习记录列表
	jLlists: '/classstudyhistory/lists',
}

export {
	request,
	api
}
